import React, { useState,useEffect } from "react";
import { Container } from "react-bootstrap";
import handleViewport from "react-in-viewport";

import HomepageBanner from "./Banner/Banner";

import Header from "./Header/Header";
import Footer from "./Footer/Footer";

import loadable from '@loadable/component'
import StaticPage from "./Banner/StaticPage";
import ContactUs from "./ContactUs/Contact";
import ContactPage from "./Banner/ContactPage";
import NoBannerPage from "./Banner/NoBanner";
import LandingPage from "./Banner/LandingPage";
import AboutUs from "./AboutUs/AboutUs";
import LocalLiving from "./LocalLiving/LocalLiving";
import LocalNews from "./LocalNews/LocalNews";
import VideoLandingPage from "./Banner/VideoLandingPage";
import News from "./News/News";
import ReviewBanner from "./Banner/ReviewBanner";
import BgImagePage from "./Banner/BgImagePage";
import VipList from "./Vip/Vip"
import Teams from "./Teams/Teams";
import { Helmet } from "react-helmet";

// import AboutUs from "./AboutUs/AboutUs";
//const AboutUs =loadable(() => import ('./AboutUs/AboutUs'))

const AtGlance =loadable(() => import ('./AtGlance/AtGlance'))
// import AtGlance from "./AtGlance/AtGlance";

// import Awards from "./Awards/Awards";
const Awards =loadable(() => import ('./Awards/Awards'))

// import BgImagePage from "./Banner/BgImagePage";
//const BgImagePage =loadable(() => import ('./Banner/BgImagePage'))

// import ContactPage from "./Banner/ContactPage";
//const ContactPage =loadable(() => import ('./Banner/ContactPage'))

// import LandingPage from "./Banner/LandingPage";
//const LandingPage =loadable(() => import ('./Banner/LandingPage'))

// import NoBannerPage from "./Banner/NoBanner";
//const NoBannerPage =loadable(() => import ('./Banner/NoBanner'))

// import ReviewBanner from "./Banner/ReviewBanner";
//const ReviewBanner =loadable(() => import ('./Banner/ReviewBanner'))

//const StaticPage =loadable(() => import ('./Banner/StaticPage'))

// import VideoLandingPage from "./Banner/VideoLandingPage";
//const VideoLandingPage =loadable(() => import ('./Banner/VideoLandingPage'))

// import BookAppointment from "./BookAppointment/BookAppointment";
const BookAppointment =loadable(() => import ('./BookAppointment/BookAppointment'))

// import Bookcard from "./BookCard/BookCard";
const Bookcard =loadable(() => import ('./BookCard/BookCard'))

// import Careers from "./Careers/Careers";
const Careers =loadable(() => import ('./Careers/Careers'))

// import ContactUs from "./ContactUs/Contact";
//const ContactUs =loadable(() => import ('./ContactUs/Contact'))

// import CoreValues from "./CoreValues/CoreValues";
const CoreValues =loadable(() => import ('./CoreValues/CoreValues'))

// import Counter from "./Counter/Counter";
const Counter =loadable(() => import ('./Counter/Counter'))

// import CustomerReview from "./CustomerReview/CustomerReview";
const CustomerReview =loadable(() => import ('./CustomerReview/CustomerReview'))

// import CustomerStories from "./CustomerStories/CustomerStories";
const CustomerStories =loadable(() => import ('./CustomerStories/CustomerStories'))

// import Events from "./Events/Events";
const Events =loadable(() => import ('./Events/Events'))

// import FeaturedEvent from "./FeaturedEvent/FeaturedEvent";
const FeaturedEvent =loadable(() => import ('./FeaturedEvent/FeaturedEvent'))

// import GardenSquares from "./GardenSquares/GardenSquares";
const GardenSquares =loadable(() => import ('./GardenSquares/GardenSquares'))

// import GardenSquaresList from "./GardenSquares/GardenSquaresList";
const GardenSquaresList =loadable(() => import ('./GardenSquares/GardenSquaresList'))

// import GoogleMap from "./GoogleMap/GoogleMap";
const GoogleMap =loadable(() => import ('./GoogleMap/GoogleMap'))

// import LandlordsServicesTable from "./LandlordsServicesTable/LandlordsServicesTable";
const LandlordsServicesTable =loadable(() => import ('./LandlordsServicesTable/LandlordsServicesTable'))

// import LocalLiving from "./LocalLiving/LocalLiving";
//const LocalLiving =loadable(() => import ('./LocalLiving/LocalLiving'))

// import LocalNews from "./LocalNews/LocalNews";
//const LocalNews =loadable(() => import ('./LocalNews/LocalNews'))

// import MissionBox from "./Mission/Mission";
const MissionBox =loadable(() => import ('./Mission/Mission'))

// import MissionAccordian from "./Mission/MissionAccordian";
const MissionAccordian =loadable(() => import ('./Mission/MissionAccordian'))

// import News from "./News/News";
//const News =loadable(() => import ('./News/News'))

// import VipList from "./Vip/Vip";
//const VipList =loadable(() => import ('./Vip/Vip'))

// import Newsevents from "./NewsEvents/NewsEvents";
const Newsevents =loadable(() => import ('./NewsEvents/NewsEvents'))

// import Offices from "./Offices/Offices";
const Offices =loadable(() => import ('./Offices/Offices'))

// import PropertiesByStatus from "./PropertiesByStatus/PropertiesByStatus";
const PropertiesByStatus =loadable(() => import ('./PropertiesByStatus/PropertiesByStatus'))

// import GallerySlider from "./PropertyManagement/GallerySlider";
const GallerySlider =loadable(() => import ('./PropertyManagement/GallerySlider'))

// import PropertyManagement from "./PropertyManagement/PropertyManagement";
const PropertyManagement =loadable(() => import ('./PropertyManagement/PropertyManagement'))

// import PropertySpecilist from "./PropertySpecilist/PropertySpecilist";
const PropertySpecilist =loadable(() => import ('./PropertySpecilist/PropertySpecilist'))

// import SellWithUs from "./SellWithUs/SellWithUs";
const SellWithUs =loadable(() => import ('./SellWithUs/SellWithUs'))

// import Teams from "./Teams/Teams";
//const Teams =loadable(() => import ('./Teams/Teams'))


const LocalNewsBlk = props => {
    const { inViewport, forwardedRef, enterCount } = props;
    return (
        <div ref={forwardedRef}>
            {(inViewport || enterCount > 0) && <LocalNews {...props}/>}
        </div>
    )
}

const LocalNewsBlock = handleViewport(LocalNewsBlk, {
    config: {
        disconnectOnLeave: true
    }
});

const CounterBlk = props => {
    const { inViewport, forwardedRef, enterCount } = props;
    return (
        <div ref={forwardedRef}>
            {(inViewport || enterCount > 0) && <Counter {...props}/>}
        </div>
    )
}

const CounterBlock = handleViewport(CounterBlk, {
    config: {
        disconnectOnLeave: true
    }
});

const GardenSquaresListBlk = props => {
    const { inViewport, forwardedRef, enterCount } = props;
    return (
        <div ref={forwardedRef}>
            {(inViewport || enterCount > 0) && <GardenSquaresList {...props}/>}
        </div>
    )
}

const GardenSquaresListBlock = handleViewport(GardenSquaresListBlk, {
    config: {
        disconnectOnLeave: true
    }
})


const Modules = (props) => { 
    const [singleMap,setSingleMap]=useState(false);
    useEffect(() => {
        if(props?.page?._id == "6411a7bbc68e0e54494bfe6e"){
            setSingleMap(true)
        }
        else if(props?.page?._id == "64119af7c68e0e54494bfe21"){
            setSingleMap(true)
        }
        else if(props?.page?._id == "6411c1b4c68e0e54494bff4d"){
            setSingleMap(true)
        }
        else if(props?.page?._id == "641b1e56e4646d0908d91269"){
            setSingleMap(true)
        }
        else if(props?.page?._id == "6411ea7bc68e0e54494bffe0"){
            setSingleMap(true)
        }
        else if(props?.page?._id == "6411e0f6c68e0e54494bffb7"){
            setSingleMap(true)
        }
        else if(props?.page?._id == "6411f4f2c68e0e54494c000a"){
            setSingleMap(true)
        }
        else if(props?.page?._id == "6411b75bc68e0e54494bff08"){
            setSingleMap(true)
        }
    }, []);
    const layoutClass = props.page.Layout
    ? `layout-${props.page.Layout.replace(/_/g, '-').toLowerCase()}`
    : '';
  
    const pathClass = props.path
    ? `page-${props.page.Pagename.replace(/[\s_]+/g, '-').toLowerCase()}`
    : '';
  
   const wrapperClass = `${layoutClass} ${pathClass}`.trim(); 

    return (
        <div className={`${props?.page?.CSS_Class || ''} ${wrapperClass}`}>
        { props.page.Pagename === "Let" &&
         <Helmet>
              <script type="application/ld+json">
                 {`{
                     "@context": "http://schema.org/",
                     "@type": "Service",
                     "brand": "tlc estate and letting agents",
                     "serviceOutput": "Properties to let",
                     "serviceType": "Renting out a property",
                     "description": "Do you have a property to let in Kensington and Chelsea? Our award-winning lettings experts can guarantee seamless tenancy management.",
                     "image": {
                         "@type": "ImageObject",
                         "caption": "Renting out a property in Kensington & Chelsea",
                         "contentUrl": "https://www.tlclondon.com/let/"
                     },
                     "mainEntityOfPage": "https://www.tlclondon.com/let/",
                     "name": "Letting your property in Kensington and Chelsea | tlc Estate and Letting Agents."               
                      }`}
              </script>
            </Helmet>}
            <Header
                menuLinks={props.page.Layout === "Banner_with_Links" ? props?.modules?.filter(c => c.List_of_Links !== undefined)[0]?.List_of_Links : []}
            />
            {props.page.Layout === "Banner_with_Links" && 
                <HomepageBanner
                    data={props.page}
                    modules={props.modules}
                />
            }
            {(props.page.Layout === "Banner_with_Images") && 
                <LandingPage
                    data={props.page}
                    modules={props.modules}
                />
            }
            {(props.page.Layout === "Banner_with_Bg_Image") && 
                <BgImagePage
                    data={props.page}
                    modules={props.modules}
                    form={false}
                    context={props.pageContext}
                />
            }
            {(props.page.Layout === "Banner_with_Bg_Image_Form") && 
                <BgImagePage
                    data={props.page}
                    modules={props.modules}
                    form={true}
                    uri={props.uri}
                    path={props.path}
                    location={props.location}
                    context={props.pageContext}
                />
            }
            {(props.page.Layout === "Contact") && 
                <ContactPage
                    data={props.page}
                    modules={props.modules}
                />
            }
            {(props.page.Layout === "No_Banner") && 
                <NoBannerPage
                    data={props.page}
                    modules={props.modules}
                    context={props.pageContext}
                />
            }
            {props.page.Layout === "Background_Video" && 
                <VideoLandingPage
                    data={props.page}
                    modules={props.modules}
                    imagename="articles.Banner_Image.h_banner"
                    context={props.pageContext}
                />
            }
            {props.page.Layout === "Static_with_Bg_Image" && 
                <StaticPage
                    data={props.page}
                    modules={props.modules}
                />
            }
            {props.page.Layout === "Contact_Form" && 
                <ContactUs
                    data={props.page}
                    modules={props.modules}
                />
            }
            {props.page.Layout === "Google_Reviews" && 
                <ReviewBanner
                    data={props.page}
                    module={props.modules.filter(c => c.Customer_Stories !== undefined)[0]}
                    className={props.page.CSS_Class}
                />
            }
            {props.modules.map((module, i) => 
                <React.Fragment key={i}>
                    {module.Data_Type === "List_Stats" && 
                        <CounterBlock
                            data={module}
                        />
                    }
                    {module.Type === "Horizontal_Lists" && 
                        <PropertySpecilist
                            data={module}
                        />
                    }
                    {module.Data_Type === "Rightside_Video" && 
                        <FeaturedEvent
                            data={module}
                            class ="ffffe"
                        />
                    }
                    {module.Choose_Event &&
                        <FeaturedEvent
                            data={{
                                Sm_Title: "Featured Event",
                                Title: module.Choose_Event.Title,
                                Video_Url: module.Choose_Event.Video_Url,
                                Content: module.Choose_Event.Description, 
                                Tile_Image: module.Choose_Event.Tile_Image, 
                                Date: module.Choose_Event.Date, 
                                Title_Italic: "",
                                EventId: module.Choose_Event.EventId,
                                Link: "/local-living/events/"+module.Choose_Event.Url+'/',
                                Select_Team: null
                            }}
                            class ="test"
                            events={true}
                        />
                    }
                    {module.Type === "Split_View_Video_Ask" && 
                        <AboutUs
                            data={module}
                        />
                    }
                    {module.Data_Type === "Background_Image" && 
                        <LocalLiving
                            data={module}
                        />
                    }
                    {module.Data_Type === "Google_Reviews" && 
                        <CustomerReview
                            data={module}
                        />
                    }
                    {/* {module.Choose_Collecttion === "Google_Reviews_List" && 
                        <Reviews/>
                    } */}
                    {module.Choose_Collection_Type === "Teams" && 
                        <Teams
                            data={props.page}
                            content={module}
                            path={props.path}
                            location={props.location}
                        />
                    }
                    {module.Choose_Collecttion === "Events" && 
                        <Events
                            data={props.page}
                            content={module}
                            path={props.path}
                            location={props.location}
                        />
                    }
                    {module.Choose_Collection_Type === "News" && 
                        <News
                            data={props.page}
                            content={module}
                            location={props.location}
                            path={props.path}
                        />
                    }
                    {module.Choose_Collection_Type === "Vip_List" && 
                        <VipList
                            data={props.page}
                            content={module}
                            location={props.location}
                            path={props.path}
                        />
                    }
                    {module.Choose_Collecttion === "News_Collection_Homepage" && 
                        <Newsevents
                            path="/about/latest-property-news-and-videos/"
                            data={module}
                        />
                    }
                    {module.Choose_Collecttion === "Offices" && 
                        <Offices
                            uri={props.uri}
                        />
                    }
                    {module.Data_Type === "Garden_Squares" && 
                        <GardenSquares
                            data={module}
                        />
                    }
                    {module.Data_Type === "Awards" && 
                        <AtGlance
                            data={module}
                        />
                    }
                    {module.Choose_Collecttion === "Awards" && 
                        <Awards
                            data={module}
                        />
                    }
                    {module.Type === "About_List_2" && 
                        <MissionBox
                            data={module}
                        />
                    }
                    {module.Type === "About_List_6" && 
                        <CoreValues
                            data={module}
                        />
                    }
                    {module.Fluid_Images && module.Fluid_Images.length > 0 && 
                        <GallerySlider
                            images={module.Fluid_Images}
                        />
                    }
                    {module.Choose_Collection_Type === "Form" && 
                        <PropertyManagement
                            data={module}
                            page={props.page}
                        />
                    }
                    
                    {(typeof module.Accordian_List !== "undefined") && 
                        <React.Fragment>
                            {(module.Layout === null || module.Layout === "One") &&
                                <SellWithUs
                                    data={module}
                                />
                            }
                            {/* {console.log(props.modules.map((c, t) => c.Layout === "Two" ? t : null).filter(c => c != null))} */}
                            {(module.Layout === "Two" && props.modules.filter(c => c.Layout === "Two").length === 2 && props.modules.map((c, t) => c.Layout === "Two" ? t : null).filter(c => c != null)[1] === i) &&
                                <MissionAccordian
                                    data={props.modules.filter(c => c.Layout === "Two")}
                                    index={i}
                                />
                            }
                        </React.Fragment>
                    }
                    {(module.Customer_Stories && props.page.Layout !== "Google_Reviews") && 
                        <CustomerStories 
                            data={module}
                        />
                    }
                    {module.Choose_Collecttion === "Properties_By_Status" && 
                        <PropertiesByStatus
                            data={module}
                            module={props?.globalModule?.Book_A_Appointment}
                            lettings={props?.path?.includes("rent") || props?.path?.includes("let")}
                        />
                    }
                    {module.Choose_Collecttion === "Careers" && 
                        <Careers
                            data={module}
                            path={props.path}
                        />
                    }
                    {module.Choose_Collecttion === "Garden_Squares" && 
                        <GardenSquaresListBlock/>
                    }
                    {(module.Iframe_Src && module.Show) && 
                        <div className="about-section pb-0">
                            <div className="w-100 map-module">
                                <iframe src={module.Iframe_Src} width={"100%"} height="100%"/>
                            </div>
                        </div>
                    }
                    {(module.Latitude && module.Longitude && module.Show) && 
                        <div className="pb-0">
                            <div className="w-100 map-module">
                                <GoogleMap
                                    data={singleMap == true ? props.page : props.gardenSquares}
                                    lat={parseFloat(module.Latitude)}
                                    lng={parseFloat(module.Longitude)}
                                    singleMap={singleMap}
                                />
                            </div>
                        </div>
                    }
                    {module.Choose_Collecttion === "Local_News" && 
                        <LocalNewsBlock/>
                    }
                    {module.Type === "Property_Landlords" && 
                        <LandlordsServicesTable
                            data={module}
                        />
                    }
                    {(module.Book_Title && props.page.Layout !== "Static_with_Bg_Image") && 
                        <Container>
                            <Bookcard
                                bookcardTitle={module?.Book_Title}
                                bookcardContent={module?.Description}
                                bookcardBtn={module?.Cta_Label}
                                bookcardLink={module.Cta_Link}
                            />
                        </Container>
                    }
                    {(module.Lets_Work_Title) && 
                        <BookAppointment
                            data={Object.assign(module, { Title: module.Lets_Work_Title })}
                        />
                    }
                </React.Fragment>
            )}
            {props.page.Show_Valuation_Block &&
                <BookAppointment
                    data={props.globalModule?.Lets_Work_Together}
                />
            }
            <Footer
                popularSearch={props.page?.Popular_Search} currentPage={props.page?.Pagename} showBreadcrumb = {true}
            />
            
        </div>
    );
};

export default Modules;